import { AnimatedSpinner, Dropdown } from '@arachas/core/lib';
import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';

import { commonFormStylesIdentifier } from '../../constants/styleConstants';
import { isFieldError, mapDropdownOptions } from '../../helpers';
import { getDeviceData, getDeviceMake, getDeviceTypes } from './deviceLookupService';

const DeviceLookup = ({ errors, touched, values, handleOnSelect, setFieldValue, setFieldTouched }) => {
  const [resources, setResources] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!resources.type) {
      setIsLoading(true);
      const promises = [getDeviceTypes()]
      if(values.deviceMake) promises.push(getDeviceMake(values.deviceType));
      if(values.deviceModel) promises.push(getDeviceData(values.deviceType, values.deviceMake));
      Promise.all(promises).then(([type, make, model]) => {
        setResources({ ...resources, type, make, model });
        setIsLoading(false);
      });
    }
  }, []);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if(resources.type && values.deviceModel && resources.model.length > 0) {
        const modelId = resources.model.find((modelItem) => {
          if(modelItem.model === values.deviceModel) {
            return modelItem;
          }
        })
        if(modelId) {
          handleModelChange({target: { value: modelId.id }})
        }
    }
  }, [resources])

  const clearDeviceMake = () => {
    setFieldValue('deviceMake', '');
    setFieldTouched('deviceMake', false);
  };

  const clearDeviceModel = () => {
    setFieldValue('deviceModel', '');
    setFieldTouched('deviceModel', false);
  };

  const handleTypeChange = (changeEvent) => {
    setIsLoading(true);
    clearDeviceMake();
    clearDeviceModel();
    const type = changeEvent.target.value;
    setFieldValue('deviceType', type);
    setFieldTouched('deviceType', true);
    getDeviceMake(type).then(make => {
      setResources({ ...resources, make });
      setIsLoading(false);
    });
  };

  const handleMakeChange = (changeEvent) => {
    setIsLoading(true);
    clearDeviceModel();
    const make = changeEvent.target.value;
    setFieldValue('deviceMake', make);
    setFieldTouched('deviceMake', true);
    getDeviceData(values.deviceType, make).then(model => {
      setResources({ ...resources, model });
      setIsLoading(false);
    });
  };

  const handleModelChange = (changeEvent) => {
    const modelId = changeEvent.target.value;
    setFieldValue('deviceModel', modelId);
    setFieldTouched('deviceModel', true);
    const device = resources.model.find(model => model.id === modelId);
    handleOnSelect(device);
  };

  const getMakeDropdown = () => resources.make && values.deviceType && (
    <div
      className={`${commonFormStylesIdentifier}--withMarginTop`}>
      <label htmlFor="deviceMake" className={`${commonFormStylesIdentifier}__fieldLabel`}>Make</label>
      <Dropdown
        error={isFieldError('deviceMake', touched, errors)}
        errorMessage={errors.deviceMake}
        name="deviceMake"
        placeholder="Type here"
        type="text"
        value={values.deviceMake}
        onChange={handleMakeChange}
        onBlur={handleMakeChange}
      >
        <option value="">Select here</option>
        {mapDropdownOptions(resources.make)}
      </Dropdown>
    </div>
  );

  const getModelDropdown = () => resources.model && values.deviceMake && (
    <div
      className={`${commonFormStylesIdentifier}--withMarginTop`}>
      <label htmlFor="deviceModel" className={`${commonFormStylesIdentifier}__fieldLabel`}>Model</label>
      <Dropdown
        error={isFieldError('deviceModel', touched, errors)}
        errorMessage={errors.deviceModel}
        name="deviceModel"
        placeholder="Type here"
        type="text"
        value={values.deviceModel}
        onChange={handleModelChange}
        onBlur={handleModelChange}
      >
        <option value="">Select here</option>
        {mapDropdownOptions(resources.model.map(({ id, model }) => ({ displayName: model, dropdownValue: id })))}
      </Dropdown>
    </div>
  );

  if (isLoading) return <AnimatedSpinner/>;

  return (
    <div>
      {resources.type && (
        <div className={`${commonFormStylesIdentifier}--withMarginTop`}>
          <label htmlFor="deviceType" className={`${commonFormStylesIdentifier}__fieldLabel`}>Device</label>
          <Dropdown
            error={isFieldError('deviceType', touched, errors)}
            errorMessage={errors.deviceType}
            name="deviceType"
            placeholder="Type here"
            type="text"
            value={values.deviceType}
            onChange={handleTypeChange}
            onBlur={handleTypeChange}
          >
            <option value="">Select here</option>
            {mapDropdownOptions(resources.type)}
          </Dropdown>
        </div>
      )}
      {getMakeDropdown()}
      {getModelDropdown()}
    </div>
  );
};

DeviceLookup.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleOnSelect: PropTypes.func,
  setFieldTouched: PropTypes.func
};

export default DeviceLookup;
