/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import App from './components/App';
import LoadUserRoute from './components/LoadUserRoute';
import PrivateRoute from './components/PrivateRoute';
import AddRenewalDate from './pages/AddRenewalDate';
import { AmendPolicyPage } from './pages/AmendPolicyPage';
import AssumptionsPage from './pages/AssumptionsPage';
import { AssumptionsThankYouPage } from './pages/AssumptionsThankYouPage';
import { BreakdownAssistance } from './pages/BreakdownAssistance';
import ChangePassword from './pages/ChangePassword';
import { ComingSoon } from './pages/ComingSoon';
import { DeviceViewQuote } from './pages/DeviceViewQuote';
import { DocumentsPage } from './pages/Documents';
import { GenericErrorPage } from './pages/GenericErrorPage';
import { GetGenericQuotePage } from './pages/GetGenericQuotePage';
import { GetQuoteThankYouGenericPage } from './pages/GetQuoteThankYouGenericPage';
import { GetQuoteThankYouPage } from './pages/GetQuoteThankYouPage';
import { LoginPage } from './pages/LoginPage';
import { Logout } from './pages/Logout';
import { ManageInsuranceProductPage } from './pages/ManageInsuranceProductPage';
import { ManageMyInsuranceDashboard } from './pages/ManageMyInsuranceDashboard';
import { MarketingConsentForm } from './pages/MarketingConsentForm';
import { MedicalAssistance } from './pages/MedicalAssistance';
import { MotorViewQuote } from './pages/MotorViewQuote';
import MyProfile from './pages/MyProfile';
import { NewPassword } from './pages/NewPassword';
import PaymentsPage from './pages/PaymentsPage';
import { PaymentsThankYouPage } from './pages/PaymentsThankYouPage';
import { PolicyLinksPage } from './pages/PolicyLinksPage';
import { ProductLandingPage } from './pages/ProductLandingPage';
import RenewalDates from './pages/RenewalDates';
import { RenewPolicyPage } from './pages/RenewPolicyPage';
import { ResetPassword } from './pages/ResetPassword';
import Signup from './pages/Signup';
import { SubmitClaimPage } from './pages/SubmitClaimPage';
import SummaryPage from './pages/SummaryPage';
import TravelDeclaration from './pages/TravelDeclaration/TravelDeclaration';
import { VanViewQuote } from './pages/VanViewQuote';
//survey removed for the moment date 17th October 2019
// import SurveyPage from './pages/SurveyPage';
import VerifyEmail from './pages/VerifyEmail';
import ViewMultiplePoliciesPage from './pages/ViewMultiplePoliciesPage';
import { ViewQuote } from './pages/ViewQuote';
import ViewTravelQuotePage from './pages/ViewTravelQuotePage';
import WalletLandingPage from './pages/WalletLandingPage';
import type { RouterPropsType } from './types/commonTypes';

export interface BOIRoute {
  url: string;
  showInMenu: boolean;
  displayBack: boolean;
}

export const routes = {
  Wallet: {
    url: '/wallet',
    showInMenu: true,
    displayBack: false,
    pageType: 'Homepage',
    pageCategory: 'Wallet Page'
  },
  Documents: {
    url: '/documents',
    showInMenu: true,
    displayBack: true,
    pageType: 'Documents',
    pageCategory: 'Documents Page'
  },
  // More: { url: '/more', showInMenu: true },
  comingSoon: {
    url: '/wallet/comingsoon',
    showInMenu: true,
    displayBack: false,
    pageType: 'Notification',
    pageCategory: 'Coming Soon Page'
  },
  helpPage: {
    url: `/wallet/help`,
    showInMenu: true,
    displayBack: false,
    pageType: 'Help',
    pageCategory: 'Help Page'
  },
  policyLinks: {
    url: '/documents/policy/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Policy View Page'
  },
  viewDocumentsLandingPage: {
    url: '/documents/landing/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Documents',
    pageCategory: 'Policy Menu Page'
  },
  uploadDocumentsPage: {
    url: '/documents/upload/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Documents',
    pageCategory: 'Document Upload Page'
  },
  viewUploadedDocumentsPage: {
    url: '/documents/viewUploads/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Documents',
    pageCategory: 'Documents View Page'
  },
  productLandingPage: {
    url: '/wallet/product/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Personal',
    pageCategory: 'Landing'
  },
  renewPolicyPage: {
    url: '/wallet/renewPolicy/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Policy Renewal Page'
  },
  amendPolicyPage: {
    url: '/wallet/amendPolicy/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Policy Amend Page'
  },
  submitClaimPage: {
    url: '/wallet/submitClaim/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Documents',
    pageCategory: 'Submit Claim Page'
  },
  medicalAssistance: {
    url: '/wallet/medicalAssistance/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Documents',
    pageCategory: 'Medical Assistance Page'
  },
  breakdownAssistance: {
    url: '/wallet/breakdownAssistance/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Documents',
    pageCategory: 'Breakdown Assistance Page'
  },
  MakePayment: {
    url: '/wallet/makePayment/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Quote',
    pageCategory: 'Payment Page'
  },
  Summary: {
    url: '/wallet/summary/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Quote',
    pageCategory: 'Summary Page'
  },
  Assumptions: {
    url: '/wallet/assumptions/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Quote',
    pageCategory: 'Assumptions Page'
  },
  AssumptionsThankYou: {
    url: '/wallet/assumptionsthankyou/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Quote',
    pageCategory: 'Assumptions Result Page'
  },
  viewQuote: {
    url: '/wallet/viewQuote/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Quote',
    pageCategory: 'View Quote Page'
  },
  paymentThankYou: {
    url: '/wallet/paymentthankyou/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Purchase',
    pageCategory: 'Purchase Page'
  },
  getQuoteThankYouPage: {
    url: '/wallet/getquotethankyou/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Quote',
    pageCategory: 'Quote Loading Page'
  },
  getGenericQuotePage: {
    url: '/wallet/getgenericquote/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Quote',
    pageCategory: 'Get Quote Page'
  },
  getVanRegPage: {
    url: '/wallet/getquote/vanregistration/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Quote',
    pageCategory: 'Lookup Page'
  },
  getTravelDestination: {
    url: '/wallet/getquote/travelDestination/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Quote',
    pageCategory: 'Lookup Page'
  },
  getDeclarationPage: {
    url: '/wallet/declaration/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Quote',
    pageCategory: 'Declaration Page'
  },
  loginPage: {
    url: '/login',
    showInMenu: false,
    displayBack: false,
    pageType: 'Login',
    pageCategory: 'Login Page'
  },
  logoutPage: {
    url: '/logout',
    showInMenu: false,
    displayBack: false,
    pageType: 'Logout',
    pageCategory: 'Logout Page'
  },
  newPassword: {
    url: '/newpassword',
    showInMenu: false,
    displayBack: false,
    pageType: 'Forgot Password',
    pageCategory: 'New Password Page'
  },
  sendResetPassword: {
    url: '/sendresetpassword',
    showInMenu: false,
    displayBack: false,
    pageType: 'Forgot Password',
    pageCategory: 'Send Reset Password Page'
  },
  resetPassword: {
    url: '/resetpassword',
    showInMenu: false,
    displayBack: false,
    pageType: 'Forgot Password',
    pageCategory: 'Password Reset Page'
  },
  manageMyInsuranceDashboard: {
    url: '/manage',
    showInMenu: false,
    displayBack: true,
    pageType: 'Documents',
    pageCategory: 'Insurance Dashboard Page'
  },
  manageMyInsuranceProduct: {
    url: '/manage/product/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Documents',
    pageCategory: 'Policy Menu Page'
  },
  genericErrorPage: {
    url: '/wallet/referraldecline/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Error',
    pageCategory: 'Generic Error Page'
  },
  signup: {
    url: '/signup/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Register',
    pageCategory: 'Sign Up Page'
  },
  verifyEmail: {
    url: '/verifyEmail/',
    showInMenu: false,
    displayBack: false,
    pageType: 'Register',
    pageCategory: 'Verify Email Page'
  },
  viewMultipleQuotes: {
    url: '/wallet/viewMultipleQuotes/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Quote',
    pageCategory: 'View Quotes Page'
  },
  viewMultiplePolicies: {
    url: '/manage/viewMultiplePolicies/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Quote',
    pageCategory: 'View Quotes Page'
  },
  marketingConsent: {
    url: '/wallet/consent/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Quote',
    pageCategory: 'Consent Page'
  },
  deviceLookup: {
    url: '/wallet/device-lookup/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Quote',
    pageCategory: 'Lookup Page'
  },
  agentLatestQuotes: {
    url: '/wallet/latest-quotes/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Agent Dashboard',
    pageCategory: 'Client Quotes Page'
  },
  changeofVehicle: {
    url: '/amendPolicy/change-vehicle/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Change Vehicle Page'
  },
  changeofVehicleThankYou: {
    url: '/amendPolicy/change-vehicle/thank-you/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Change Vehicle Result Page'
  },
  addingDriver: {
    url: '/amendPolicy/adding-driver/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Add Driver Page'
  },
  cancelPolicy: {
    url: '/amendPolicy/cancel-policy/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Cancel Policy Page'
  },
  updateSpecifiedItems: {
    url: '/amendPolicy/update-specified-items/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Update Specified Items Page'
  },
  mtaThankYouPage: {
    url: '/amendPolicy/mta/thank-you/',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Cancel Result Page'
  },
  messages: {
    url: '/messages',
    showInMenu: true,
    displayBack: true,
    pageType: 'Notifications',
    pageCategory: 'Messages Page'
  },
  myProfile: {
    url: '/my-profile',
    showInMenu: true,
    displayBack: true,
    pageType: 'Profile',
    pageCategory: 'My Profile Page'
  },
  changePassword: {
    url: '/changepassword',
    showInMenu: false,
    displayBack: true,
    pageType: 'Profile',
    pageCategory: 'Change Password Page'
  },
  renewalDates: {
    url: '/renewaldates',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Renew Date Page'
  },
  addRenewalDate: {
    url: '/renewaldates/add',
    showInMenu: false,
    displayBack: true,
    pageType: 'Policy',
    pageCategory: 'Add Renewal Date Page'
  }
};

export const BOIRoutes = () => (
  <Switch>
    <Route exact path="/">
      <Redirect to="/wallet" />
    </Route>
    <Route>
      {() => (
        <App>
          <Switch>
            <Route
              exact
              path={routes.Wallet.url}
              render={(props: RouterPropsType) => (
                <WalletLandingPage {...props} />
              )}
            />
            <PrivateRoute
              exact
              path={routes.Documents.url}
              component={DocumentsPage}
            />
            {/*example of the routes in the next route component
             /wallet/product/home
            /wallet/product/travel
            /wallet/product/car */}

            <Route exact path={routes.comingSoon.url} component={ComingSoon} />
            <LoadUserRoute
              exact
              path={`${routes.productLandingPage.url}:type(home|travel|car|device)`}
              component={ProductLandingPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.marketingConsent.url}:type(home|car|travel)`}
              component={MarketingConsentForm}
            />
            <PrivateRoute
              exact
              path={`${routes.manageMyInsuranceProduct.url}:type(home|travel|car|device|van)`}
              component={ManageInsuranceProductPage}
            />
            <PrivateRoute
              exact
              path={`${routes.renewPolicyPage.url}:type(home|travel|car|van)`}
              component={RenewPolicyPage}
            />
            <PrivateRoute
              exact
              path={`${routes.amendPolicyPage.url}:type(home|travel|car|device)`}
              component={AmendPolicyPage}
            />
            <PrivateRoute
              exact
              path={`${routes.submitClaimPage.url}:type(home|travel|car|device)`}
              component={SubmitClaimPage}
            />
            <PrivateRoute
              exact
              path={`${routes.medicalAssistance.url}:type(travel)`}
              component={MedicalAssistance}
            />
            <PrivateRoute
              exact
              path={`${routes.breakdownAssistance.url}:type(car|van)`}
              component={BreakdownAssistance}
            />
            <LoadUserRoute
              exact
              path={`${routes.getQuoteThankYouPage.url}:type(travel|car)`}
              component={GetQuoteThankYouGenericPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.getDeclarationPage.url}:type(travel)`}
              component={TravelDeclaration}
            />
            <LoadUserRoute
              exact
              path={`${routes.getGenericQuotePage.url}:type(home|travel|car|van)`}
              component={GetGenericQuotePage}
            />
            <LoadUserRoute
              exact
              path={`${routes.getQuoteThankYouPage.url}:type(home)`}
              component={GetQuoteThankYouPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.viewQuote.url}:type(home)`}
              component={ViewQuote}
            />
            <LoadUserRoute
              exact
              path={`${routes.viewQuote.url}:type(travel)`}
              component={ViewTravelQuotePage}
            />
            <LoadUserRoute
              exact
              path={`${routes.viewQuote.url}:type(car)`}
              component={MotorViewQuote}
            />
            <LoadUserRoute
              exact
              path={`${routes.viewQuote.url}:type(car)`}
              component={VanViewQuote}
            />
            <LoadUserRoute
              exact
              path={`${routes.paymentThankYou.url}:type(home|travel|car|device|van)`}
              component={PaymentsThankYouPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.AssumptionsThankYou.url}:type(car|van|device|home)`}
              component={AssumptionsThankYouPage}
            />
            <PrivateRoute
              exact
              path={`${routes.policyLinks.url}:type(home|travel|car|device)`}
              component={PolicyLinksPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.MakePayment.url}:type(home|travel|car|device)`}
              component={PaymentsPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.Summary.url}:type(home|travel|car|van)`}
              component={SummaryPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.Assumptions.url}:type(home|van|device|home)`}
              component={AssumptionsPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.viewQuote.url}:type(device)`}
              component={DeviceViewQuote}
            />
            {/* 
            survey removed for the moment date 17th October 2019
            <Route exact path={`${routes.surveyPage.url}:type(home|travel|car)`} component={SurveyPage} /> */}
            <Route exact path={routes.loginPage.url} component={LoginPage} />
            <Route exact path={routes.logoutPage.url} component={Logout} />
            <Route
              exact
              path={routes.newPassword.url}
              component={NewPassword}
            />
            <Route
              exact
              path={routes.sendResetPassword.url}
              component={ResetPassword}
            />
            <Route
              exact
              path={routes.resetPassword.url}
              render={(routeProps: RouterPropsType) => (<ResetPassword sendStatus {...routeProps} />)}
            />
            <Route exact path={routes.signup.url} component={Signup} />
            <Route
              exact
              path={routes.verifyEmail.url}
              component={VerifyEmail}
            />
            <Route
              exact
              path={`${routes.genericErrorPage.url}:type(home|travel|car|van|device)`}
              component={GenericErrorPage}
            />
            <PrivateRoute
              exact
              path={routes.manageMyInsuranceDashboard.url}
              component={ManageMyInsuranceDashboard}
            />
            <PrivateRoute
              exact
              path={`${routes.genericErrorPage.url}:type(home|travel|car|van|device)`}
              component={GenericErrorPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.genericErrorPage.url}:type(home|travel|car|van|device)`}
              component={GenericErrorPage}
            />
            <LoadUserRoute
              exact
              path={`${routes.viewMultipleQuotes.url}:type(travel)`}
              component={ViewTravelQuotePage}
            />
            <PrivateRoute
              exact
              path={`${routes.viewMultiplePolicies.url}:type(home|car|travel|device|van)`}
              component={ViewMultiplePoliciesPage}
            />
            <PrivateRoute
              exact
              path={routes.myProfile.url}
              component={MyProfile}
            />
            <PrivateRoute
              exact
              path={routes.changePassword.url}
              component={ChangePassword}
            />
            <PrivateRoute
              exact
              path={routes.renewalDates.url}
              component={RenewalDates}
            />
            <PrivateRoute
              exact
              path={routes.addRenewalDate.url}
              component={AddRenewalDate}
            />
          </Switch>
        </App>
      )}
    </Route>
  </Switch>
);
