/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */
import './GenericClaimPage.scss';
import '../../styles/commonPageStyles.scss';

import {
  InputTextArea
} from '@arachas/core/lib';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import DropdownDatepickerWrapper from '../../components/DropdownDatepickerWrapper/DropdownDatepickerWrapper';
import { birthInitialAndStartDates } from '../../configs/DateConfig';
import { commonFormStylesIdentifier } from '../../constants';
import MultipleDocUpload from './MultipleDocUpload';

const className = 'c-GenericClaimPage';

const GenericClaimForm = (props) => {

  const { isFieldError, handleBlur,
    errors, setTouched, touched, values,
    setFieldValue, setFieldTouched, handleChange,
    insuranceType,
    accessToken, policyNumber, resources, match } = props;

  const documentType = resources.document_name[0];

  return (
    <Form>
      <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="dateOfIncident" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Date of Incident
          </label>
          <span className={`${className}__input`}>
            <DropdownDatepickerWrapper
              startDate={birthInitialAndStartDates.startDate}
              endDate={birthInitialAndStartDates.endDate}
              value={values.dateOfIncident}
              name='dateOfIncident'
              errors={errors}
              stringFormat={true}
              touched={touched}
              touchedCallback={() => {
                setFieldTouched('dateOfIncident')
              }}
              onChange={(selectedDate) => {
                setFieldValue('dateOfIncident', selectedDate)
              }}
            />
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label htmlFor="descriptionOfIncident" className={`${commonFormStylesIdentifier}__fieldLabel`}>
            Incident Description
          </label>
          <span className={`${className}__input`}>
            <InputTextArea
              error={isFieldError('descriptionOfIncident', touched, errors)}
              errorMessage={errors.descriptionOfIncident}
              onBlur={handleBlur}
              name="descriptionOfIncident"
              placeholder="Type Here"
              maxlength="250"
              onChange={handleChange}
              setTouched={setTouched}
              values={values.descriptionOfIncident}
              touched={touched.descriptionOfIncident}
            />
          </span>
        </div>
        <MultipleDocUpload {...props}
          policyNumber={policyNumber}
          errors={errors.docUpload}
          touched={touched.docUpload}
          setFieldTouched={setFieldTouched}
          handleBlur={handleBlur}
          resources={resources}
          setFieldValue={setFieldValue}
          setTouched={setTouched}
          documentType={documentType}
          insuranceType={insuranceType}
          match={match}
          accessToken={accessToken}

        />
      </div>
    </Form>
  );
};

GenericClaimForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setTouched: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  policyNumber: PropTypes.string,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  isFieldError: PropTypes.func,
  insuranceType: PropTypes.string,
  accessToken: PropTypes.string,
  resources: PropTypes.object,
  match: PropTypes.object,
};


export default GenericClaimForm;