import { routes } from '../routes';

const formatPayload = (payload) => {
  return payload ? { body: JSON.stringify(payload) } : {};
};

const setAuthorizationToken = (authToken) => {
  return authToken ? { Authorization: `Bearer ${authToken}` } : {};
};

const redirectToLoginPage = (history = {}) => {
  if (typeof history.push === 'function') {
    history.push(routes.loginPage.url);
  }
};

const redirectToHomePageWithCacheRefresh = () => {
  const baseUrl = `${process.env.PUBLIC_URL}/`;
  window.location.replace(baseUrl);
};

const checkResponseForBody = (response, onError) => {
  try {
    const res = response.json();
    return res.then(res => res,
      (error) => {
        if (onError) {
          onError();
        }
        return { error: true, errorMessage: error.message };
      });
  } catch (error) {
    if (onError) {
      onError();
    }
    return new Promise(res => res({ error: true, errorMessage: error.message }));
  }
};

const checkResponseForFile = (response, onError) => {
  try {
    const res = response.blob();
    return res.then(res => res,
      (error) => {
        if (onError) {
          onError();
        }
        return { error: true, errorMessage: error.message };
      });
  } catch (error) {
    if (onError) {
      onError();
    }
    return new Promise(res => res({ error: true, errorMessage: error.message }));
  }
};

export const makeRequest = async ({ url, method, authToken, payload, onError, settingsOverride = {}, history = {} }) => {
  const settings = {
    method: method,
    headers: {
      Accept: 'application/json',
      ...setAuthorizationToken(authToken),
      'Content-Type': 'application/json',
      'x-custom-ui-version': 'develop/2314',
    },
    ...formatPayload(payload),
  };
  const finalSettings = { ...settings, ...settingsOverride };
  return fetch(
    url,
    finalSettings,
  ).then(response => {
    if (response.status === 412) {
      // response 412 means there is a mismatch between ui version and backend version
      redirectToHomePageWithCacheRefresh();
    }
    if (response.status === 401) {
      // The user got an unauthorized call and therefore needs login
      redirectToLoginPage(history);
    }
    return checkResponseForBody(response, onError);
  },
    (error) => {
      if (onError) {
        onError();
      }
      return new Promise(res => res({ error: true, errorMessage: error.message }));
    },
  );
};

export const makeRequestResponseIsFile = async ({ url, method, authToken, onError, settingsOverride = {}, history = {} }) => {
  const settings = {
    method: method,
    headers: {
      Accept: 'application/json',
      ...setAuthorizationToken(authToken),
      'Content-Type': 'application/json',
      'x-custom-ui-version': 'develop/2314',
    },
  };
  const finalSettings = { ...settings, ...settingsOverride };
  return fetch(
    url,
    finalSettings,
  ).then(response => {
    if (response.status === 412) {
      // response 412 means there is a mismatch between ui version and backend version
      redirectToHomePageWithCacheRefresh();
    }
    if (response.status === 401) {
      // The user got an unauthorized call and therefore needs login
      redirectToLoginPage(history);
    }
    return checkResponseForFile(response, onError);
  },
    (error) => {
      if (onError) {
        onError();
      }
      return new Promise(res => res({ error: true, errorMessage: error.message }));
    },
  );
};
