/*
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 */

import '../../styles/commonFormStyles.scss';
import './DeviceViewQuote.scss';

import { DesktopCard, Title, TitleWithUnderLine } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import BrandLogo from '../../components/BrandLogo';
import { MobileBackButton } from '../../components/MobileBackButton';
import { DevicePremiumBreakdown } from '../../components/PremiumBreakdowns';
import ProgressBar from '../../components/ProgressBar';
import QuoteDisplay from '../../components/QuoteDisplay';
import QuoteInfo from '../../components/QuoteInfo';
import {
  commonPageStylesIdentifier,
  DIRECT_DEBIT_STORAGE,
} from '../../constants';
import { DEVICE_INSURANCE_TYPE } from '../../constants/insuranceTypeConstants';
import { JOURNEY_YOUR_QUOTE } from '../../constants/journey';
import {
  getPayloadObjFromSessionStorage,
  removeFromSessionStorage,
  saveInSessionStorage,
} from '../../helpers';
import { routes } from '../../routes';
import { fbqTrackEvent } from '../../scripts/facebookPixelEvents';
import DataLayers from '../../scripts/googleAnalyticsDataLayerObjects';
import {
  getlistOfFeatures,
  getShowUpToFeatureNumber,
} from './ListOfDeviceFeatures';

const DeviceViewQuote = props => {
  const { history } = props;
  const className = 'c-DeviceViewQuote';

  const insuranceType = props.match.params.type || '';
  const FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER = `${insuranceType}${DIRECT_DEBIT_STORAGE}`;
  const [quoteReference, setQuoteReference] = useState(0);
  const [price, setPrice] = useState(0);
  const [premiumDetails, setPremiumDetails] = useState({});

  useEffect(() => {
    fbqTrackEvent('Lead');
    fbqTrackEvent('Contact');
  }, [])

  useEffect(() => {
    const payload = getPayloadObjFromSessionStorage(DEVICE_INSURANCE_TYPE);
    if (payload.quote_reference) {
      setQuoteReferenceFromState(payload);
      setPriceFromState(payload);
      setPremiumDetails(payload);
    }
  }, [props.location]);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        props.history.push(
          `${routes.getGenericQuotePage.url}${DEVICE_INSURANCE_TYPE}`
        );
      }
    };
  }, [history]);

  removeFromSessionStorage(FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER);

  const setPriceFromState = quote => {
    setPrice(Number(quote.annual_total));
    TagManager.dataLayer({
      dataLayer: DataLayers.landsOnViewQuotePage({
        insuranceType: DEVICE_INSURANCE_TYPE,
        cover_premium: Number(quote.annual_total),
      }),
      dataLayerName: 'dataLayer',
    });
  };

  const setQuoteReferenceFromState = quoteModel => {
    quoteModel
      ? setQuoteReference(quoteModel.quote_reference)
      : setQuoteReference(0);
  };

  const onProceedClicked = () => {
    const quoteData = {
      price: price,
      quoteReference: quoteReference,
    };
    saveInSessionStorage(
      FULL_DIRECT_DEBIT_STORAGE_IDENTIFIER,
      JSON.stringify(quoteData)
    );
    history.push({
      pathname: `${routes.Summary.url}${insuranceType}`,
      state: quoteData,
    });
  };

  return (
    <div className={`${className}`}>
      <div className={`${commonPageStylesIdentifier}__pageTitle`}>
        <ProgressBar stage={JOURNEY_YOUR_QUOTE} />
        <TitleWithUnderLine>Your Quote</TitleWithUnderLine>
      </div>
      <DesktopCard>
        <div className={`${commonPageStylesIdentifier}__hideOnDesktop`}>
          <MobileBackButton history={history} />
        </div>
        <div className={`${className}__card`}>
          <div className={`${className}__contentContainer`}>
            <div
              className={`${className}__quoteInfoContainer ${className}__paddedContainer`}
            >
              <div className={`${className}__title`}>
                <div className={`${className}__logo`}>
                  <BrandLogo theme={'dark'} />
                </div>
                <div className={`${className}__underwrittenText`}>
                  <div className={`${className}__logo`}>
                    Underwritten by
                    <img
                      className={`${className}__zurichLogo`}
                      alt="Zurich logo"
                      src="/asset/Zurich-Logo@3x.png"
                    />
                  </div>
                </div>
                <Title
                  align="left"
                  type="h1"
                  weight="weight500"
                  variant="greyBrown"
                >
                  Key Benefits
                </Title>
              </div>
              <QuoteInfo
                quoteFeatures={getlistOfFeatures()}
                showUpToFeature={getShowUpToFeatureNumber()}
                showReadMoreOption={false}
              />
            </div>
          </div>
          <div className={`${className}__contentContainer`}>
            <div className={`${className}__paddedContainer`}>
              <DevicePremiumBreakdown premiumDetails={premiumDetails} />
            </div>
          </div>
        </div>
        <QuoteDisplay
          price={price}
          sticky
          buttonType={'proceed'}
          onClickFunction={onProceedClicked}
          title='Total payable'
        />
      </DesktopCard>
    </div>
  );
};

DeviceViewQuote.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default DeviceViewQuote;
