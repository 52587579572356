/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import '../../../../styles/commonFormStyles.scss';
import './Claims.scss';

import { Dropdown } from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { commonFormStylesIdentifier } from '../../../../constants';
import Claim from './Claim';

const emptyClaim = {
  claim_type: undefined,
  claim_date: undefined,
  claim_status: undefined,
  claim_amount: undefined
};

const untouchedEmptyClaim = {
  claim_type: false,
  claim_date: false,
  claim_status: false,
  claim_amount: false
};

const Claims = (props) => {
  const className = 'c-Claims';
  const { errors, setFieldValue, setFieldTouched, claimsTouched, claimsValues, touched, isFieldError, values } = props;

  useEffect(() => {
    updateTouchedClaims();
    setFieldValue('claimsNumber', claimsValues.length);
  }, [claimsValues]);

  const updateTouchedClaims = () => {
    const touchedArraySize = claimsTouched ? claimsTouched.length : 0;
    let newTouchedClaims = claimsTouched ? [...claimsTouched] : [];
    const arraysSizeDiff = claimsValues.length - touchedArraySize;
    for (let i = 0; i < arraysSizeDiff; ++i) {
      newTouchedClaims = [...newTouchedClaims, untouchedEmptyClaim]
    }
    setFieldTouched('claims', newTouchedClaims);
  };

  // eslint-disable-next-line complexity
  const setClaims = (numberOfClaims) => {
    let newValues = [];
    let newTouched = [];
    let claimValuesClone = [...claimsValues];
    let claimsTouchedClone = [...claimsTouched];
    if(numberOfClaims > 0) {
      if(claimValuesClone.length < numberOfClaims) {
        for (let i = claimValuesClone.length; i < numberOfClaims; i++) {
          newValues.push(Object.assign({}, emptyClaim));
          newTouched.push(Object.assign({}, untouchedEmptyClaim));
        }
      } else {
        for (let i = claimValuesClone.length; i > numberOfClaims; i--) {
          claimValuesClone.pop();
          claimsTouchedClone.pop();
        }
      }
      let newClaimsValues = claimsValues ? [...claimValuesClone] : [];
      let newTouchedClaims = claimsTouched ? [...claimsTouchedClone] : [];
      newTouched = [...newTouchedClaims, ...newTouched];
      newValues = [...newClaimsValues, ...newValues]
    }
    setFieldValue('claims', newValues);
    setFieldTouched('claims', newTouched);
  };

  const renderClaim = () => {
    const claims = [];
    for (let index = 0; index < parseInt(values.claimsNumber); ++index) {
      claims.push(<Claim {...props} index={index} key={index} />);
    }
    return claims.length > 0 ?
      <div className={`${commonFormStylesIdentifier}__dynamicFieldsContainer`}>{claims}</div> : <></>;
  };

  return (
    <div className={className}>
      <div
        className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
        <label
          htmlFor="claimsNumber"
          className={`${commonFormStylesIdentifier}__fieldLabel`}
        >
          How many claims or losses have you or any member of your
          household had in the last 5 years
        </label>
        <span className={`${className}__input`}>
          <Dropdown
            name="claimsNumber"
            value={values.claimsNumber}
            onChange={ev => {
              setClaims(parseInt(ev.target.value));
              setFieldValue('claimsNumber', parseInt(ev.target.value));
            }}
            error={isFieldError('claimsNumber', touched, errors)}
            errorMessage={errors.claimsNumber}
          >
            <option value="">Select here</option>
            <option value={0}>None</option>
            <option value={1}>Yes, a single claim up to €4,000</option>
            <option value={2}>Yes, two claims up to €1,500</option>
            <option value={3}>Yes, more than two claims</option>
          </Dropdown>
        </span>
      </div>
      {renderClaim()}
    </div>
  );
};

Claims.propTypes = {
  claimsValues: PropTypes.array,
  claimsTouched: PropTypes.array,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  isFieldError: PropTypes.func,
  claimTypes: PropTypes.array,
  touched: PropTypes.object,
  values: PropTypes.shape({
    claimsNumber: PropTypes.number
  })
};

export default Claims;
