
import './YourVanForm.scss';
import '../../../styles/commonFormStyles.scss';

import { AccordionText, Button,ButtonGroup, InputField } from '@arachas/core/lib';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import AreaOfUse from '../../../components/AreaOfUse';
import { SaveAndContinueButtonConfigs } from '../../../configs/SaveAndContinueButtonConfigs';
import { commonFormStylesIdentifier } from '../../../constants';
import { isFieldError } from '../../../helpers';
import { decimalNumberMask } from '../../../helpers/NumberMask';
import { inFormStructure } from '../../../pages/GetGenericQuotePage/getQuoteFormStructure';
import CarRegLookup from '../../CarRegLookup';
import ResourceDropdown from '../../ResourceDropdown';
import { yourVanFormIdentifier } from '../CommonFiles/commonFormConstants';

export const BaseYourVanForm = (props) => {
  const className = 'c-YourVanForm';
  const {
    errors, handleBlur, handleChange, setFieldValue, setFieldTouched, touched, values
  } = props.formik;

  const {
    resources,
    insuranceType } = props;

  const handleChangeResource = (value, name) => {
    setFieldValue(name, value || {});
  };

  return (
    <div className={`${className}__container`}>
      <div className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}>
        {inFormStructure('subAreaUse', insuranceType, yourVanFormIdentifier) &&
          <>
            <CarRegLookup
              readonly
              compressedView
              values={values}
              name="vanReg"
              errors={errors}
              errorMessage={errors.vanReg}
              placeholder="Type here"
              onChange={handleChange}
              touched={touched}
              onBlur={handleBlur}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              isFieldError={isFieldError}
              insuranceType={insuranceType}
            />
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label htmlFor='vehicleValue' className={`${commonFormStylesIdentifier}__fieldLabel`}>
                Vehicle value
              </label>
              <InputField
                error={isFieldError('vehicleValue', touched, errors)}
                errorMessage={errors.vehicleValue}
                touched={touched}
                onBlur={handleBlur}
                placeholder="Enter an amount"
                prefix={faEuroSign}
                name="vehicleValue"
                value={values.vehicleValue}
                onChange={handleChange}
                masked
                mask={decimalNumberMask}
              />
            </div>

            <div
              className={`${commonFormStylesIdentifier}__fieldContainer `}
            >
              <label htmlFor="signage" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                Does the vehicle have signage?
              </label>
              <ButtonGroup
                name="signage"
                onClick={(value) => {
                  setFieldValue('signage', value);
                  setFieldTouched('signage', true);
                }}
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true }
                ]}
                value={values.signage}
                touched={touched.signage}
                selected={values.signage}
                error={isFieldError('signage', touched, errors)}
                errorMessage={errors.signage}
              />
            </div>

            <AreaOfUse formik={props.formik} resources={resources} />

            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label htmlFor="annualKm" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                Annual KM
              </label>
              <ResourceDropdown
                error={isFieldError('annualKm', touched, errors)}
                errorMessage={errors.annualKm}
                touched={touched}
                onBlur={handleBlur}
                placeholder="Select here"
                name="annualKm"
                value={values.annualKm}
                onChange={handleChangeResource}
                values={resources.km_per_year}
              />
            </div>
            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label htmlFor="ncdYears" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                How many years do you have NCD for a Van in your own name?
              </label>
              <ResourceDropdown
                error={isFieldError('ncdYears', touched, errors)}
                errorMessage={errors.ncdYears}
                touched={touched}
                onBlur={handleBlur}
                placeholder="Select here"
                name="ncdYears"
                value={values.ncdYears}
                onChange={handleChangeResource}
                values={resources.ncd_years}
              />
            </div>

            <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
              <label htmlFor="ncdInsurer" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                What is the name of your insurer providing your NCD?
              </label>
              <ResourceDropdown
                error={isFieldError('ncdInsurer', touched, errors)}
                errorMessage={errors.ncdInsurer}
                touched={touched}
                onBlur={handleBlur}
                placeholder="Select here"
                name="ncdInsurer"
                value={values.ncdInsurer}
                onChange={handleChangeResource}
                values={resources.ncd_insurer}
              />
            </div>

            <div
              className={`${commonFormStylesIdentifier}__fieldContainer `}>
              <label htmlFor="ncdProctection" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                <AccordionText
                  label="Do you require full NCD protection?"
                  icon="info"
                  iconAlign="right"
                  customLabelClass={`${commonFormStylesIdentifier}__accordionTextFieldLabel`}>
                  <br />
                  <div className={`${commonFormStylesIdentifier}__infoText`}>
                    Your cover automatically provides 2 year stepback no claims discount protection. If you have one claim the
                    discount number of years will only reduce by 2 years instead of losing all your no claims discount.
                    However, you can select full protection when you have earned 5 years no claims discount.
                    With this cover if you make one claim in the insurance year your no claims bonus will not be affected.
                  </div>
                </AccordionText>
              </label>
              <ButtonGroup
                name="ncdProctection"
                onClick={(value) => {
                  setFieldValue('ncdProctection', value);
                  setFieldTouched('ncdProctection', true);
                }}
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true }
                ]}
                value={values.ncdProctection}
                touched={touched.ncdProctection}
                selected={values.ncdProctection}
                error={isFieldError('ncdProctection', touched, errors)}
                errorMessage={errors.ncdProctection}
              />
            </div>

            <div
              className={`${commonFormStylesIdentifier}__fieldContainer `}
            >
              <label htmlFor="privateCar" className={`${commonFormStylesIdentifier}__fieldLabel`}>
                Do you currently hold a Private Car Policy or Second Commercial Vehicle with an earned NCD?
              </label>
              <ButtonGroup
                name="privateCar"
                onClick={(value) => {
                  setFieldValue('privateCar', value);
                  setFieldTouched('privateCar', true);
                }}
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true }
                ]}
                value={values.privateCar}
                touched={touched.privateCar}
                selected={values.privateCar}
                error={isFieldError('privateCar', touched, errors)}
                errorMessage={errors.privateCar}
              />
            </div>

          </>}
      </div>
      {SaveAndContinueButtonConfigs[insuranceType]  ? 
      <div className={`${className}__button`}>
        <Button
          id="SaveAndContinue_YourVanForm"
          data-testid="SaveAndContinue_YourVanForm"
          fluid={true}
          quaternary
          onClick={()=>props.saveAndContinue("form2")}
        >
          Save & Continue
        </Button>
      </div> : null }
    </div>
  );
};

BaseYourVanForm.propTypes = {
  formik: PropTypes.object,
  insuranceType: PropTypes.string,
  resources: PropTypes.object,
  handleChangeResource: PropTypes.object,
  onChange: PropTypes.func,
  saveAndContinue: PropTypes.func
};

const YourVanForm = connect(BaseYourVanForm);
export default YourVanForm;