import { addDays,startOfToday, subYears } from 'date-fns';



const START_DATE = '01, 01, 1950';
const END_DATE = '01, 01, 2050';
const POLICY_ADDON_DATES = 28;
const TRAVEL_ADDON_DATES = 180;
const EIGHTEEN_YEARS_AND_ABOVE = 18;

export const birthInitialAndStartDates = {
    startDate: new Date('01, 01, 1950'),
    endDate: new Date(subYears(startOfToday(), EIGHTEEN_YEARS_AND_ABOVE))
}

export const policyStartDates = {
    startDate: new Date(startOfToday()),
    endDate: new Date(addDays(startOfToday(), POLICY_ADDON_DATES))
}

export const travelDates = {
    startDate: new Date(startOfToday()),
    endDate: new Date(addDays(startOfToday(), TRAVEL_ADDON_DATES))  
}

export const defaultDates = {
    startDate: new Date(START_DATE),
    endDate: new Date(END_DATE)
} 

export const purchaseDates = {
    startDate: new Date(START_DATE),
    endDate: new Date(startOfToday())
}