/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 *
 * @flow
 */

import './GenericErrorPage.scss';
import '../../styles/commonPageStyles.scss';

import { Button, DesktopCard } from '@arachas/core/lib';
import React, { useContext } from 'react';
import type { Match } from 'react-router-dom';
import { Link, withRouter } from 'react-router-dom';
import { useLocation } from "react-router-dom";

import { backToWallet } from '../../constants/buttonLabelConstants';
import { routes } from '../../routes';
import type { PayloadType } from '../../types';
import UserContext from '../../UserContext';
import DeviceErrorPage from './DeviceErrorPage';
import HomeErrorPage from './HomeErrorPage';
import MotorErrorPage from './MotorErrorPage';
import MTAErrorPage from './MTAErrorPage';
import RSAErrorPage from './RSAErrorPage';
import TravelErrorPage from './TravelErrorPage';
import VanErrorPage from './VanErrorPage';
const className = 'c-GenericErrorPage';

interface Props {
  payload?: PayloadType;
  errorType?: string;
  match: Match;
  error?: string;
}

// eslint-disable-next-line complexity
const GenericErrorPage = (props: Props) => {
  const { getUserName } = useContext(UserContext);
  let location = useLocation();

  const errorType = (() => {
    if (props.errorType) return props.errorType;
    if (location.state) return location.state.errorType;

    if (props.match.params.type) return props.match.params.type;

    return '';
  })();

  const redTextMessage =
    'We have not been able to get an online quote from our insurance partner.';

  let quoteReferenceLine =
    'If you would like to discuss your proposal for insurance further ';
  const reference = `your quotation reference is`;

  const payloadCondition = props?.payload || location?.state?.payload;

  if (payloadCondition && payloadCondition.hasOwnProperty('response') && payloadCondition.response.quote_reference) {
    quoteReferenceLine =
      `${quoteReferenceLine} ${reference} ${payloadCondition.response.quote_reference}`;
  } else {
    quoteReferenceLine = '';
  }

  const isCardError = location.state?.error === 'CARD_PAYMENT' ? true : props.error === 'CARD_PAYMENT' ? true : false;

  const firstName = getUserName();

  const errorPages = {
    '': '',
    rsa: <RSAErrorPage firstName={firstName} />,
    mta: <MTAErrorPage />,
    home: (
      <HomeErrorPage
        firstName={firstName}
        redTextMessage={redTextMessage}
        quoteReferenceLine={quoteReferenceLine}
        isCardError={isCardError}
      />
    ),
    car: <MotorErrorPage firstName={firstName} isCardError={isCardError} />,
    travel: (
      <TravelErrorPage
        firstName={firstName}
        redTextMessage={redTextMessage}
        isCardError={isCardError}
      />
    ),
    device: <DeviceErrorPage
      firstName={firstName}
    />,
    van: <VanErrorPage
      firstName={firstName} />,
    business: <div></div>,
    life: <div></div>,
    pet: <div></div>,
    health: <div></div>,
    pensions: <div></div>,
    savingsAndInvestments: <div></div>,
  };

  const getControlButtons = () => isCardError ? (
    <>
      <Link to={`${routes.MakePayment.url}${errorType}`}
        data-ga
        id={`GenericErrorPage__tryAgainButton`} data-testid={`GenericErrorPage__tryAgainButton`}>
        <Button fluid={true}>Try again</Button>
      </Link>
      <div className={`${className}__buttonContainer`}>
        <Link to={routes.Wallet.url}
          data-ga
          id={`GenericErrorPage__homeButton`} data-testid={`GenericErrorPage__homeButton`}>
          {backToWallet}
        </Link>
      </div>
    </>
  ) : (
      <Link to={routes.Wallet.url}
        data-ga
        id={`GenericErrorPage__homeButton`} data-testid={`GenericErrorPage__homeButton`}>
        <Button fluid={true}>{backToWallet}</Button>
      </Link>);

  
  return (
    <div className={`${className}`}>
      <DesktopCard>
        {errorPages[errorType]}
        <div className={`${className}__buttonContainer`}>
          {getControlButtons()}

        </div>

      </DesktopCard>
    </div>
  );
};


export default withRouter(GenericErrorPage);
