/**
 * Copyright 2015-present Singlepoint. All Rights Reserved.
 */

import './YourHomeForm.scss';
import '../../../styles/commonFormStyles.scss';

import {
  ButtonGroup,
  Dropdown,
  InputField,
} from '@arachas/core/lib';
import PropTypes from 'prop-types';
import React from 'react';

import { oneToTenValues } from '../../../constants/dropdowns';
import { HOME_COVER_TYPE } from '../../../constants/home'
import { commonFormStylesIdentifier } from '../../../constants/styleConstants';
import { isFieldError, mapDropdownOptions } from '../../../helpers';
import { yearLimit } from '../../../helpers/NumberMask';
import Claims from '../../Home/YourHomeForm/Claims';
import CoverTypeDependantFields from '../../Home/YourHomeForm/CoverTypeDependantFields';
import ResourceDropdown from '../../ResourceDropdown';
import HomeAddress from './HomeAddress';
import JointlyOwnedDetails from './JointlyOwnedDetails';

export const className = 'c-YourHomeForm';

const YourHomeForm = props => {
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleBlur,
    resources,
    setFieldTouched
  } = props;

  const coverTypeButtonValues = [
    {
      label: 'Home I own & live in',
      value: 'Home I own & live in',
      icon: 'home',
    },
    {
      label: 'Home I own & rent out',
      value: 'Home I own & rent out',
      icon: 'key',
    },
    {
      label: 'Home I own & use as a holiday home',
      value: 'Home I own & use as a holiday home',
      icon: 'sun',
    },
    {
      label: 'Rent from someone',
      value: 'Rent from someone',
      icon: 'hand',
    },
  ];

  const alarmButtonValues = [
    { label: 'No', value: 'No' },
    {
      label: 'Yes - Standard',
      value: 'Yes - Standard',
    },
    {
      label: 'Yes - Monitored',
      value: 'Yes - Monitored',
    },
  ];

  return (
    <div className={`${className}__container`}>
      <div
        className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
      >
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <HomeAddress {...props} />
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="coverType"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Type Of Cover
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                name="coverType"
                onClick={e => {
                  const type = resources.dwelling_type.find(
                    type => type.description === e
                  );
                  setFieldValue('coverType', type);
                  if (type.description === HOME_COVER_TYPE.RENT) {
                    setFieldValue('rebuildCost', '');
                  }
                }}
                options={coverTypeButtonValues}
                spaceBetween={true}
                selected={values.coverType ? values.coverType.description : ''}
                onBlur={handleBlur}
                error={isFieldError('coverType', touched, errors)}
                errorMessage={errors.coverType}
                largerButton={true}
              />
            </div>
          </span>
        </div>
        <CoverTypeDependantFields
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          touched={touched}
          values={values}
        />
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="homeType"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Type of property
          </label>
          <span className={`${className}__input`}>
            <ResourceDropdown
              placeholder="Select here"
              name="homeType"
              value={values.homeType}
              onChange={value => {
                setFieldValue('homeType', value);
                if (value.description === 'Apartment') {
                  setFieldValue('rebuildCost', '');
                } else {
                  setFieldValue('homeType', value);
                }
              }}
              onBlur={handleBlur}
              error={isFieldError('homeType', touched, errors)}
              errorMessage={errors.homeType}
              values={props.resources.type}
            />
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="yearOfConstruction"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Year of build
          </label>
          <span className={`${className}__input`}>
            <InputField
              error={isFieldError('yearOfConstruction', touched, errors)}
              placeholder="Enter year of construction"
              errorMessage={errors.yearOfConstruction}
              name="yearOfConstruction"
              value={values.yearOfConstruction}
              onChange={handleChange}
              onBlur={handleBlur}
              masked
              mask={yearLimit}
            />
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="numBedrooms"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Number of bedrooms
          </label>
          <span className={`${className}__input`}>
            <Dropdown
              name="numBedrooms"
              value={values.numBedrooms}
              onChange={handleChange}
              onBlur={handleBlur}
              error={isFieldError('numBedrooms', touched, errors)}
              errorMessage={errors.numBedrooms}
            >
              <option value="">Select a number</option>
              {mapDropdownOptions(oneToTenValues, 'bedrooms')}
            </Dropdown>
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="numBathrooms"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Number of bathrooms
          </label>
          <span className={`${className}__input`}>
            <Dropdown
              name="numBathrooms"
              value={values.numBathrooms}
              onChange={handleChange}
              onBlur={handleBlur}
              error={isFieldError('numBathrooms', touched, errors)}
              errorMessage={errors.numBathrooms}
            >
              <option value="">Select a number</option>
              {mapDropdownOptions(oneToTenValues, 'bathrooms')}
            </Dropdown>
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="heatingType"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Type of Heating
          </label>
          <span className={`${className}__input`}>
            <ResourceDropdown
              placeholder="Select here"
              name="heatingType"
              value={values.heatingType}
              onChange={value => {
                setFieldValue('heatingType', value);
              }}
              onBlur={handleBlur}
              error={isFieldError('heatingType', touched, errors)}
              errorMessage={errors.heatingType}
              values={props.resources.heating_type}
            />
          </span>
        </div>

        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="houseAlarm"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Have you a house alarm?
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                name="houseAlarm"
                onClick={e => {
                  const type = resources.alarm_type.find(
                    type => type.description === e
                  );
                  setFieldValue('houseAlarm', type);
                }}
                options={alarmButtonValues}
                spaceBetween={true}
                selected={
                  values.houseAlarm ? values.houseAlarm.description : ''
                }
                onBlur={handleBlur}
                error={isFieldError('houseAlarm', touched, errors)}
                errorMessage={errors.houseAlarm}
              />
            </div>
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="twoSmokeDetectors"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Have you at least two smoke detectors fitted?
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                name="twoSmokeDetectors"
                onClick={e => {
                  setFieldValue('twoSmokeDetectors', e);
                }}
                options={[
                  { label: 'No', value: 'No' },
                  { label: 'Yes', value: 'Yes' },
                ]}
                selected={values.twoSmokeDetectors}
                onBlur={handleBlur}
                error={isFieldError('twoSmokeDetectors', touched, errors)}
                errorMessage={errors.twoSmokeDetectors}
              />
            </div>
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="firstTimeBuyer"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Are you a first time buyer?
          </label>
          <span className={`${className}__input`}>
            <div className={`${className}--fieldLabelSpacing`}>
              <ButtonGroup
                name="firstTimeBuyer"
                onClick={e => {
                  setFieldValue('firstTimeBuyer', e);
                }}
                options={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true },
                ]}
                selected={values.firstTimeBuyer}
                onBlur={handleBlur}
                error={isFieldError('firstTimeBuyer', touched, errors)}
                errorMessage={errors.firstTimeBuyer}
              />
            </div>
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
          <label
            htmlFor="homeNoClaimsBonus"
            className={`${commonFormStylesIdentifier}__fieldLabel`}
          >
            Do you have a home insurance no claims bonus
          </label>
          <span className={`${className}__input`}>
            <ResourceDropdown
              name="homeNoClaimsBonus"
              value={values.homeNoClaimsBonus}
              onChange={value => {
                setFieldValue('homeNoClaimsBonus', value);
              }}
              onBlur={handleBlur}
              error={isFieldError('homeNoClaimsBonus', touched, errors)}
              errorMessage={errors.homeNoClaimsBonus}
              values={resources.no_claims_bonus}
            />
          </span>
        </div>
        <div className={`${commonFormStylesIdentifier}__dividerContainer`}>
          <div className={`${commonFormStylesIdentifier}__dashedBrokenLine`} />
        </div>
      </div>
      <div className={`${commonFormStylesIdentifier}__fieldContainer`}>
        <span className={`${className}__input`}>
          <Claims
            errors={errors}
            name="claims"
            numberOfClaims={
              values.claimsNumber ? parseInt(values.claimsNumber) : 0
            }
            onChange={e => setFieldValue('claims', e)}
            setFieldValue={setFieldValue}
            values={values}
            claimsTouched={touched.claims}
            claimsValues={values.claims}
            onBlur={handleBlur}
            claimTypes={resources.claim_type}
            touched={touched}
            setFieldTouched={setFieldTouched}
            isFieldError={isFieldError}
          />
        </span>
      </div>
      <div
        className={`${commonFormStylesIdentifier}__nonDynamicFieldsContainer`}
      />
      <JointlyOwnedDetails {...props} />
    </div>
  );
};

YourHomeForm.propTypes = {
  values: PropTypes.object,
  resources: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  handleBlur: PropTypes.func,
};

export default YourHomeForm;
