/**
 * package used
 * for storybook of component
 * https://jeffmcammond.com/react-select-datepicker/?path=/docs/welcome--page
 */

/* eslint-disable react/prop-types */
import '../DropdownDatepickerWrapper/DropdownDatepickerWrapper.scss';

import { InputErrorMessage } from '@arachas/core/lib';
import React, { useCallback, useEffect,useState } from 'react';
import { SelectDatepicker } from 'react-select-datepicker';
import { v4 as uuidv4 } from 'uuid';

import { fieldErrorCheck } from '../../helpers';

const uniqueIdentifier = uuidv4()
// eslint-disable-next-line no-unused-vars
export default function DropdownDatepickerWrapper(props) {
  const {
    startDate,
    endDate,
    onChange,
    name,
    touched,
    errors,
    disabled = false,
    hideLabels = true,
    touchedCallback,
    reverseYears = false,
    stringFormat = false,
    value = '',
    errorMessage = ''
  } = props;

  const [dateValue, setDateValue] = useState();


  const dateIsValid = (dateStr) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
  
    if (dateStr.match(regex) === null) {
      return false;
    }
  
    const date = new Date(dateStr);
  
    const timestamp = date.getTime();
  
    if (Number.isNaN(timestamp)) {
      return false;
    }
  
    return date.toISOString().startsWith(dateStr);
  }

  const formatDate = date => {
    // formats a JS date to 'yyyy-mm-dd'
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
  
    return [day, month, year].join("-");
  };
  // eslint-disable-next-line flowtype/no-types-missing-file-annotation
  const onDateChange = useCallback((date: Date) => {
    if (date) {
      setDateValue(date);
      if(stringFormat) {
        onChange(formatDate(date));
      } else {
        onChange(date);
      }

    }
  }, []);

  const classes = 'c-DropdownDatePickerWrapper';
  const modifiedClass = name.includes('.') ? `${name.split('.')[0]}${uniqueIdentifier}` : name;

  const convertToDate = useCallback((date) => {
    if(date) {
      if(date.includes(':')) {
        return new Date(date)
      } else {
        if(dateIsValid(date)) {
          return new Date(date)
        } else {
          const [day, month, year] = date.split('-');
          return new Date([year, month, day].join('-'))
        }
      }
    }
  }, [])

  // eslint-disable-next-line complexity
  const getErrorMessage = useCallback(() => {
    if(!touched) return ''
    if(typeof errors === 'string') {
      return errors
    } else {
      if(errors && errors[name]) {
        return errorMessage !== '' ? errorMessage : errors[name]
      } else {
        return errorMessage !== '' ? errorMessage : ''
      }
    }
  }, [touched, errors])

  const resetDatePicker = () => {
    const elements = ['day', 'year', 'month']
    elements.forEach((item) => {
      const element = document.querySelector(`.${modifiedClass} #rsd__select-${item}`)
      element.value = '-1';
    })
  }

  // eslint-disable-next-line complexity
  useEffect(() => {
    if(value !== '') {
      switch(true) {
        case value === null:
          setDateValue(null)
          resetDatePicker();
          break;
        case value !== '':
          setDateValue(convertToDate(value.toString()));
          break;
      }
    }
  }, [value]);



  return (
    <div data-testid='dropdownPickerContainer' className={`${classes} ${modifiedClass}`} onClick={() => {
       touchedCallback() 
    }}>
      <InputErrorMessage
        id={`dropdownDatePickerMessage`}
        error={fieldErrorCheck(name, touched, errors)}
        errorMessage={getErrorMessage() || ''}
        data-testid='error-message-dropdown-datepicker'
      />
      <SelectDatepicker
        labels={{
          dayPlaceholder: 'Day',
          monthPlaceholder: 'Month',
          yearPlaceholder: 'Year'
        }}
        selectedDate={dateValue || null}
        onDateChange={onDateChange}
        order={'day/month/year'}
        className={`${classes}__container`}
        disabled={disabled}
        hideLabels={hideLabels}
        minDate={startDate}
        maxDate={endDate}
        reverseYears={reverseYears}
        data-testid={'date-picker-component'}
      />
    </div>
  );
}
